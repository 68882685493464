import Footer from "Footer";
import React from "react";
import "./About.css";
import Header from "./Header";
import { ReactComponent as Logo } from "./svg/about_us_logo.svg";
import { ReactComponent as InstaLogo } from "./svg/instagram.svg";
import { ReactComponent as MailLogo } from "./svg/mail.svg";
import { ReactComponent as LinkednLogo } from "./svg/linked_in.svg";
import { ReactComponent as WhatsAppLogo } from "./svg/whatsapp.svg";
import { ReactComponent as FacebookLogo } from "./svg/facebook.svg";
import { ReactComponent as TPrev } from "./svg/arrow_left.svg";
import { Link, useNavigate } from "react-router-dom";

import Nahush from "./images/nahushJ.webp";
import Mansukh from "./images/mansukhkaur.webp";
import GoToTop from "GoToTop";
function About() {
  let navigate = useNavigate();
  return (
    <div className="about__us">
      <Header type="BLCH" />



      <div className="step_slider">
          <div className="back_icon" onClick={() => navigate(-1)}>
            <TPrev />
          </div>

          <div className="step_slider_info" style={{"textAlign" : "right"}}>
            <p className="tm">Get to know</p>
            <p className="hm">About Us</p>
          </div>

        </div>
      <div className="about__us__image__container">
        <Logo />
      </div>
      <div className="about__us__links">
      <a href="http://wa.me/917042336219" target="_blank" rel="noopener noreferrer">
        <WhatsAppLogo />
        </a>

        <a href="mailto:wannabebawse@gmail.com" target="_blank" rel="noopener noreferrer">
        <MailLogo />
        </a>

        <a href="http://instagram.com/wannabebawse" target="_blank" rel="noopener noreferrer">
        <InstaLogo />
        </a>
        
        <a href="https://www.facebook.com/wannabebawse" target="_blank" rel="noopener noreferrer">
        <FacebookLogo />
        </a>
      </div>
      <div className="about__us__info">
        <p className="bl">
          Wannabe Bawse <em>by Mansukh Kaur</em> was launched in 2019 with an
          intent to normalize the stigma around mental health.
          <br />
          We began development & delivery of yearly planners in 2020 pan India
          to encourage folks to begin with Journaling.
          <br />
          The whole planner has been revised for 2022 & is available in a bunch
          of themes - Evening Clouds, Deep Waves and Sand Dunes
        </p>
      </div>
      <div className="seperator"></div>

      <p className="hl">About the Team</p>
      <div className="about__us__image__container">
      <img src={Mansukh} alt="" />
      </div>

      <div className="about__us__info">
        <div className="name__designation">
          <p className="tl">Mansukh Kaur</p>
          <p className="lm">Founder, CTO, Wannabe Bawse</p>
        </div>
        <div className="about__us__links">
        <a href="mailto:mansukhkaur@ouhlook.com" target="_blank" rel="noopener noreferrer">
        <MailLogo />
        </a>

         <a href="https://www.linkedin.com/in/mansukh-kaur/" target="_blank" rel="noopener noreferrer">
          <LinkednLogo />
        </a>


        </div>
        <p className="bl">
          Psychology ethusiast, React Dev, entrepreneur. Operating at the
          crossroads of simplicity & computer science to craft an inspiring,
          compelling & authentic brand narrative. Putting constant efforts
          towards normalizing mental health.
        </p>
      </div>
      <div className="seperator"></div>

      <div className="about__us__image__container">
        <img src={Nahush} alt="" />
      </div>

      <div className="about__us__info">
        <div className="name__designation">
          <p className="tl">Nahush J</p>

          <p className="lm">Design Lead, Wannabe Bawse</p>
        </div>

        <div className="about__us__links">
        <a href="http://instagram.com/heynahush" target="_blank" rel="noopener noreferrer">
        <InstaLogo />
        </a>
        <a href="mailto:heynahush@gmail.com" target="_blank" rel="noopener noreferrer">
        <MailLogo />
        </a>
        <a href="https://www.linkedin.com/in/heynahush/" target="_blank" rel="noopener noreferrer">
          <LinkednLogo />
          </a>
        </div>
        <p className="bl">
          Coffee-addict, Figma-fanatic, Self-starter. Making at the sweet spot
          between design & function to express ideas through design. Often
          makes random things with friends.
        </p>
      </div>
      <div className="seperator"></div>

      <Footer />
      <GoToTop/>
    </div>
  );
}

export default About;
