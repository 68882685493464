export const cartInitialState = { cart:[], };

export const getCartTotal= (cart) => {
   // console.log("Cart is ", cart[0].price);
    
    return (cart?.reduce((amount, item)=>item.price+amount, 0));
}

export const getCartProductNames= (cart) => {
    // console.log("Cart is ", cart[0].price);
     let a = [];
     cart.forEach(function(obj){
         console.log("items names",obj.name)
         a.push(obj.name)
     })
     return a;
 }


const CartReducer = (state, action) => {
    switch (action.type) {
        case "ADD_TO_CART" :
            return {
                ...state.cart, 
                cart : [...state.cart, action.item]
            };

            case "REMOVE_FROM_CART":
                const index = state.cart.findIndex(
                  (cartItem) => cartItem.id === action.id
                );
                let newCart = [...state.cart];
          
                if (index >= 0) {
                  newCart.splice(index, 1);
          
                } else {
                  console.warn(
                    `Cant remove product (id: ${action.id}) as its not in Cart!`
                  )
                }
          
                return {
                  ...state,
                  cart: newCart
                }
        default :
            return state;
    }
};

export default CartReducer;