import React, { useState, useContext } from "react";
import Stepper from "./Stepper";
import Header from "./Header";
import Loading from "./Loading";
import ImageSlider from "./ImageSlider";
import Button from "./Button";
import "./StepTwo.css";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "./context/UserContext";
import GoToTop from "GoToTop";
import { ReactComponent as X } from "./svg/x_black.svg";
import cover1 from "./images/1.webp";
import cover2 from "./images/2.webp";
import cover3 from "./images/3.webp";

import { ReactComponent as TPrev } from "./svg/arrow_left.svg";
import { ReactComponent as TNext } from "./svg/arrow_right.svg";

const quotes = [
  {
    image: cover1,
  },
  {
    image: cover2,
  },
  {
    image: cover3,
  },
];

function StepTwo() {
  const user_context = useContext(UserContext);

  console.log(user_context.userQuote);

  const [userQuote, setUserQuote] = user_context.userQuote;

  const [mode, setMode] = useState(0);

  const changeMode = (e, id) => {
    setMode(id);
    console.log(mode);
  };

  const pickQuote = (e) => {
    hideModal();
    let quoteSelected = e.currentTarget.querySelector("p").innerText;
    document.getElementById("userQuoteInput").value = quoteSelected;
    setUserQuote(quoteSelected);
    console.log(quoteSelected);
  };

  const pickQuoteFromTextarea = (e) => {
    let quoteSelected = document.getElementById("userQuoteInput").value;
    setUserQuote(quoteSelected);
    console.log(quoteSelected);
  };

  const showModal = () => {
    document.getElementById("modal").classList.add("slideUp");
  };

  const hideModal = () => {
    document.getElementById("modal").classList.remove("slideUp");
  };

  let navigate = useNavigate();
  const [isSlideSet, setIsSlideSet] = useState(false);
  const checkInput = (e) => {
    if (userQuote === "") {
      alert(
        "Kindly Select a Quote from Quote Library or write your own to Proceed Further! "
      );
    } else navigate("/step3");
  };

  return (
    <div className="step__two">
      <Header type="BLCH" />

      <div className="step__two__container">
        {/**
         * <Stepper
          stepNumber="2"
          totalSteps="5"
          stepName="Choose A Quote"
          nextStep="Planner Selection"
        />
         */}

        <div className="step_slider">
          <div className="back_icon" onClick={() => navigate(-1)}>
            <TPrev />
          </div>
          <div className="step_slider_info">
            <p className="tm">Step 2 of 5</p>
            <p className="hl">Quote</p>
          </div>
          <div className="next_icon" onClick={(e) => checkInput(e)}>
            <TNext />
          </div>
        </div>

        <div className="background">
          <div className="image_details_slider_container">
            <div className="Image__slider__box">
              <Loading step="2" />
              <ImageSlider Images={quotes} />
            </div>
          
          </div>
        </div>

        <div className="option__selector">
        <p className="hs" style={{marginTop:"-12px"}}>Quote for your cover:</p>
          <textarea
            id="userQuoteInput"
            placeholder="You can do anything if you put your mind to it"
            onInput={(e) => pickQuoteFromTextarea(e)}
          ></textarea>
          <p className="bl" style={{textAlign:"left"}}>
            Unable to find a quote for your cover? Choose from our handpicked
            quotes
          </p>

          <div className="quote__button" onClick={showModal}>
            <Button title="Quote Library" icon="library" type="shaded" />
          </div>
        </div>

        <div id="modal" className="quotes__library__modal__container">
          <div className="quotes__library__modal">
            <div className="quotes__library__title">
              <p className="hs">Tap to select</p>
              <div
                id="modal_close_button_container"
                style={{ cursor: "pointer" }}
                onClick={hideModal}
              >
               <X/>
              </div>
            </div>
            <div className="quote" onClick={(e) => pickQuote(e)}>
              <p className="bl">
                What you get by achieving your goals is not as important as what
                you become by achieving your goals.
              </p>
            </div>
            <div className="quote" onClick={(e) => pickQuote(e)}>
              <p className="bl">
                Never bend your head. Always hold it high. Look the world
                straight in the eye.
              </p>
            </div>
            <div className="quote" onClick={(e) => pickQuote(e)}>
              <p className="bl">
                You can do anything if you put your mind to it
              </p>
            </div>
            <div className="quote" onClick={(e) => pickQuote(e)}>
              <p className="bl">
                When you have a dream, you've got to grab it and never let go.
              </p>
            </div>
            <div className="quote" onClick={(e) => pickQuote(e)}>
              <p className="bl">Live Laugh Love</p>
            </div>
          </div>
        </div>
      </div>

      <div className="step1__floatingButton" onClick={(e) => checkInput(e)}>
        <Button title="Next" icon="next" type="full" />
      </div>
      <GoToTop />
    </div>
  );
}

export default StepTwo;
