import React, { useState, useContext } from "react";

import Header from "./Header";
import Loading from "./Loading";
import ImageSlider from "./ImageSlider";
import Button from "./Button";
import "./StepFour.css";
import DetailsSlider from "./DetailsSlider";
import { ImageContext } from "./context/ImageContext";
import { ChecklistOptionsContext } from "./context/ChecklistOptionsContext";
import { Link, useNavigate } from "react-router-dom";
import Checklist from "./Checklist";
import { UserContext } from "./context/UserContext";
import GoToTop from "GoToTop";

import { ReactComponent as TPrev } from "./svg/arrow_left.svg";
import { ReactComponent as TNext } from "./svg/arrow_right.svg";

import finance1 from "./images/waves/finance_tracker.webp";
import finance2 from "./images/dunes/finance.webp";
import finance3 from "./images/clouds/finance_tracker.webp";

import fitness1 from "./images/waves/finance_tracker.webp";
import fitness2 from "./images/dunes/finance.webp";
import fitness3 from "./images/clouds/fitness_tracker.webp";

import mplan1 from "./images/waves/meal_planner.webp";
import mplan2 from "./images/dunes/meal.webp";
import mplan3 from "./images/clouds/meal_planner.webp";

import ptrack1 from "./images/waves/period_tracker.webp";
import ptrack3 from "./images/clouds/periods_tracker.webp";

import week1 from "./images/waves/weekly_schedule.webp";
import week2 from "./images/dunes/weekly_scheduler.webp";
import week3 from "./images/clouds/weekly_scheduler.webp";
import week4 from "./images/clouds/weekly_scheduler_back.webp";

import { ReactComponent as Tick } from "./svg/checked.svg";

const Ftracker = [
  {
    image: finance1,
  },
  {
    image: finance2,
  },
  {
    image: finance3,
  },
];

const Fittracker = [
  {
    image: fitness1,
  },
  {
    image: fitness2,
  },
  {
    image: fitness3,
  },
];

const Mplan = [
  {
    image: mplan1,
  },
  {
    image: mplan2,
  },
  {
    image: mplan3,
  },
];

const PTrack = [
  {
    image: ptrack1,
  },

  {
    image: ptrack3,
  },
];

const WeekPlan = [
  {
    image: week1,
  },
  {
    image: week2,
  },
  {
    image: week3,
  },
  {
    image: week4,
  },
];

export const AddOns = [
  {
    name: "Finance Tracker",
    writeup:
      "Monthly expense tracking worksheets  + Saving Tracker to record your savings for whole year",
    serial: Ftracker,
    price: 30,
    pages: 24,
  },
  {
    name: "Fitness Planner",
    writeup:
      "Monthly Physical Activity Tracking Worksheets to track your fitness journey    ",
    serial: Fittracker,
    price: 30,
    pages: 24,
  },
  {
    name: "Meal Planner",
    writeup:
      "Ease your Meal Prep Process with weekly meal planner. Make Grocery Lists, Write Recipes etc",
    serial: Mplan,
    price: 85,
    pages: 80,
  },
  {
    name: "Productivity Bundle",
    writeup:
      "Tracker for those hectic weeks that require precise hourly schedules",
    serial: WeekPlan,
    price: 85,
    pages: 80,
  },

  {
    name: "Blank Note Bundle",
    writeup: "30 extra pages for someone who loves journaling random thoughts",
    serial: WeekPlan,
    price: 30,
    pages: 30,
  },
  {
    name: "Periods Tracker",
    writeup: "Track days of your menstrual cycles throughout the year",
    serial: PTrack,
    price: 0,
    pages: 1,
  },
];

const pagesAddonsOptions = [
  { id: 0, name: "3 extra months", price: 100 },
  { id: 1, name: "6 extra months", price: 200 },
];

function StepFour() {
  let navigate = useNavigate();

  const user_context = useContext(UserContext);

  const [userAddons, setUserAddons] = user_context.userAddons;

  const [userAddonsPrice, setUserAddonsPrice] = user_context.userAddonsPrice;

  const [slides, setSlides] = useState(Ftracker);
  const [chosen, setChosen] = useState([]);

  setUserAddons(chosen);

  console.log(userAddonsPrice);
  const AddOnsPriceTotal = chosen?.reduce(
    (amount, item) => item.price + amount,
    0
  );

  return (
    <div className="step__four">
      <Header type="BLCH" />
      {console.log(AddOnsPriceTotal)}
      <div className="step__four__container">
        <div className="step_slider">
          <div className="back_icon" onClick={() => navigate(-1)}>
            <TPrev />
          </div>

          <div className="step_slider_info">
            <p className="tm">Step 4 of 5</p>
            <p className="hl">Add Ons</p>
          </div>

          <div className="next_icon" onClick={() => navigate("/step5")}>
            <TNext />
          </div>
        </div>

        <div className="background">
          <div className="image_details_slider_container">
            <div className="Image__slider__box">
              <Loading step="4" />
              <ImageSlider Images={slides} />
            </div>

            <ImageContext.Provider value={{ slides, setSlides }}>
              <DetailsSlider Details={AddOns} />
            </ImageContext.Provider>
          </div>
        </div>

        <div className="option__selector">
          <p className="hs left_align">Your selections:</p>
          <div className="checklist_option option_selected">
            <div className="option_selection_bg">
              <div className="option_selector show" id="tick_icon">
                <Tick />
              </div>
            </div>

            <p className="tm" id="option_name">
              Ikigai
            </p>
            <p className="ll" style={{ paddingLeft: "12px" }}>
              OFFER
            </p>
          </div>

          <ChecklistOptionsContext.Provider value={{ chosen, setChosen }}>
            <Checklist />
          </ChecklistOptionsContext.Provider>
        </div>

        <div className="option__selector">
          <p className="hs left_align">Undated bundle</p>

          <ChecklistOptionsContext.Provider value={{ chosen, setChosen }}>
            <Checklist optionNames={pagesAddonsOptions} />
          </ChecklistOptionsContext.Provider>
          <div className="types_banner">
            <p className="bl">
              {" "}
              <b>Undated page bundle</b> is now available in the addons to
              complete a full year
            </p>
          </div>
        </div>
      </div>

      <div className="step1__floatingButton">
        <Link to="/step5">
          <Button title="Next" icon="next" type="full" />
        </Link>
      </div>
      <GoToTop />
    </div>
  );
}

export default StepFour;
