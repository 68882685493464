import React, {useContext} from 'react'
import './RadioButton.css'

import RadioOn from './svg/radio_on.svg'
import RadioOff from './svg/radio_off.svg'

import { RadioOptionContext } from "context/RadioContext";

function RadioButton(props) {
    const {option, setOption}=useContext(RadioOptionContext);

    const selectOption = (e,index,radOpt) =>
    {let elt=e.currentTarget.classList;
        e.preventDefault();
        
        let allOptions=document.getElementsByClassName("radio__option__icon");
  for (var i=0, len=allOptions.length|0; i<len; i=i+1|0)
  {
    allOptions[i].style.background=`url(${RadioOff})`;
    allOptions[i].style.backgroundRepeat="no-repeat";
    allOptions[i].style.backgroundPosition="center";
  }

  document.getElementById("radio"+index).style.background=`url(${RadioOn})`;
  document.getElementById("radio"+index).style.backgroundRepeat="no-repeat";
  document.getElementById("radio"+index).style.backgroundPosition="center";
  
  setOption(radOpt);
    }

    return (
        <div className='radio__button__container'>
            
           {
                props.options.map((radOpt,index)=>{
                    return(
                        <div className="radio__option" onClick={(e)=>{selectOption(e,index,radOpt)}}>
                            <div className="radio__option__icon" id={"radio"+index}>

                            </div>
                            <p className="tm">{radOpt}</p>
                        </div>
                    )
                })
           }
        </div>
    )
}

export default RadioButton

RadioButton.defaultProps={
    options : ["Evening Clouds", "Deep Waves", "Sand Dunes"]
}