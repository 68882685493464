
import React from 'react'
import './PaymentSuccess.css'
import { ReactComponent as LogoShaded } from "./svg/WB_logo_shaded.svg";
import { Link } from "react-router-dom";
import Button from "./Button";

function PaymentSuccess() {
    return (
        <div className='payment__success'>
             <div className="logo_container">
        <LogoShaded />
        <p className="tl">Normalising Mental Health</p>
      </div>
            <div className="payment_alert">
                <p className="ds">Payment Success</p>
                <p className="bl">You will recieve your order tracking details in 1-2 business days</p>
            </div>


            <div className="step1__floatingButton">
        <Link to="/">
          <Button title="Back To Home"  type="full" />
        </Link>
      </div>
        </div>
    )
}

export default PaymentSuccess
