import React, {useState} from "react";
import "./Login.css";
import { ReactComponent as Xshaded } from "./svg/x_black.svg";
import { ReactComponent as LogoShaded } from "./svg/WB_logo_shaded.svg";
import Button from "./Button";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { RecaptchaVerifier } from "firebase/auth";
import { auth, createUserDocument } from "./firebase";
import { signInWithPhoneNumber } from "firebase/auth";
import { useAuth } from "./context/AuthContext";

function Login() {

  let navigate = useNavigate();
  let location = useLocation();

  let from = location.state?.from?.pathname || "/";
  const [userName, setUserName] = useAuth()[1].userName;
  console.log(useAuth[1])
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [mobile, setMobile] = useState("");
  const [otp, setOTP] = useState("");
  const [appVerifier , setAppVerifier ] = useState();
  const [confirmationResult, setConfirmationResult] = useState();
  const [showCredentialsInput, setShowCredentialsInput] = useState(true);
  
  const configureCaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        onSignInSubmit();
      },
      defaultCountry : "IN"
    }, auth);

    return(window.recaptchaVerifier);
  }


  const  onSignInSubmit = async() => {

    
    
    let appV = await configureCaptcha();

    const phoneNumber="+91"+mobile 
    console.log(phoneNumber, auth, appV);

    signInWithPhoneNumber(auth, phoneNumber , appV)
    .then((confirmationResult) => {
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      window.confirmationResult = confirmationResult;
      console.log("OTP has been sent")
      setConfirmationResult(window.confirmationResult);
      // ...
    }).catch((error) => {
      // Error; SMS not sent
      
      // ...
    });

setShowCredentialsInput(!showCredentialsInput);

  } 

  const onSubmitOTP = (e) => {
    let code = otp;

    console.log(otp);
  confirmationResult.confirm(code).then((result) => {
  // User signed in successfully.
  const user = result.user;
  console.log("OTP verified");
   createUserDocument(user,fname, lname);
  // ...
  navigate(from, { replace: true });
  setUserName(fname);
}).catch((error) => {
 
  // User couldn't sign in (bad verification code?)
  // ...
});

  }

  return (

    

    <div className="login_page">
      <div className="login_closing_icon">
          <Link to="/">
        <Xshaded />
        </Link>
      </div>

      <div className="logo_container">
        <LogoShaded />
        <p className="small">Normalising Mental Health</p>
      </div>

      <div id="sign-in-button"></div>

     { showCredentialsInput && <div className="credentials_input_container">

        <div className="input_box">
          <p className="bm">First name</p>
          <input
            required
            onChange={(e) => {
              setFname(e.target.value);
            }}
            type="text"
            name="fname"
            id="fname"
          />
        </div>

        <div className="input_box">
          <p className="bm">Last name</p>
          <input
            required
            onChange={(e) => {
              setLname(e.target.value);
            }}
            type="text"
            name="lname"
            id="lname"
          />
        </div>

        <div className="input_box">
          <p className="bm">Mobile Number</p>
          <input
            required
            onChange={(e) => {
              setMobile(e.target.value);
            }}
            type="number"
            max="9999999999"
            min="1000000000"
            name="mobile"
            id="mobile"
          />
        </div>

        

        <div className="get_otp_button_container"  onClick={(e) => onSignInSubmit()}>
          <Button type="purple" title="Get OTP" icon="login white"/>
      </div>
      </div> }

      { showCredentialsInput==false && <div className="otp_input_container">
          <h4 style={{textAlign:"left", width: "100%"}}>Hi {fname}, </h4>

          <div className="input_box">
          <p className="small">Please enter your OTP</p>
          <input
            required
            onChange={(e) => {
              setOTP(e.target.value);
            }}
            type="number"
            max="999999"
            min="100000"
            name="otp"
            id="otp"
          />
        </div>

        <div className="login_button_container" onClick={(e) => onSubmitOTP(e)}>
          <Button type="shaded" title="Log In" icon="login"/>
      </div>
      </div> }

      <p className="small" style={{textAlign:"center"}}>
      On tapping Verify, you would recieve an OTP on your given number, which will have to be entered to login.
      </p>
    </div>
  );
}

export default Login;
