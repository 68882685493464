import React, { useContext, useState } from "react";
import "./CartProduct.css";
import { ReactComponent as Trash } from "./svg/trash.svg";
import { CartContext } from "./context/CartContext";

function CartProduct(props) {
  //const [userPlannerImage, setUserPlannerImage] = useState({hero3})
  const [cartState, cartDispatch] = useContext(CartContext);

  console.log(props.theme);
  //props.theme==="Evening Clouds" ? setUserPlannerImage({hero1}) : props.theme==="Deep Waves" ? setUserPlannerImage({hero2}) : props.theme==="Sand Dunes"?   setUserPlannerImage({hero3}) :
  // setUserPlannerImage({hero3})
  const removeFromCart = () => {
    // remove the item from the basket
    cartDispatch({
      type: "REMOVE_FROM_CART",
      id: props.id,
    });
    console.log("Cart has ", cartState);
  };
  return (
    <div className="cart_product_container">
      <div className="cart_product">
        <div className="img_box">
          <img src={props.image} alt="" id="product_image" />
          {console.log([props.image])}
        </div>
        <div className="product_details">
          <p className="tm">{props.theme}</p>
          <p className="small">{props.name}</p>
          
        </div>
        <div className="product_right_side">
          {props.type !== "addon" && <div
            onClick={(e) => {
              removeFromCart();
            }}
          >
            <Trash />
          </div>}
          <p className="lm" id="product_price">
            ₹ {props.price}
          </p>
        </div>
      </div>

      <div className="cart_card_marker"></div>
    </div>
  );
}

export default CartProduct;
