import React, { useState, useContext } from "react";
import Stepper from "./Stepper";
import Header from "./Header";
import Loading from "./Loading";
import ImageSlider from "./ImageSlider";
import Button from "./Button";
import "./StepFive.css";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Upload } from "./svg/camera.svg";
import app from "./firebase.js";
import { getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { useAuth } from "./context/AuthContext";
import GoToTop from "GoToTop";
import { UserContext } from "./context/UserContext";
import theme3_8 from "./images/waves/6B.webp";
import theme2_8 from "./images/dunes/6C.webp";
import theme1_8 from "./images/clouds/5A.webp";

import { ReactComponent as TPrev } from "./svg/arrow_left.svg";
import { ReactComponent as TNext } from "./svg/arrow_right.svg";

const pics = [
  {
    image: theme3_8,
  },
  {
    image: theme2_8,
  },
  {
    image: theme1_8,
  },
];

function StepFive() {
  const phoneNumber = useAuth()[0].phoneNumber;
  console.log(phoneNumber);

  const [slides, setSlides] = useState(pics);

  let navigate = useNavigate();

  const [isSlideSet, setIsSlideSet] = useState(false);
  const checkInput = (e) => {
    if (!isSlideSet) {
      alert("Kindly Upload Image to Proceed Further! ");
    } else if (nameOnPlanner === "") {
      alert(
        "Kindly enter the name to be printed on planner to Proceed Further! "
      );
    } else navigate("/review");
  };

  const [percentUploaded, setPercentUploaded] = useState(0);

  const loadImage = (e) => {
    let uploadedImage = URL.createObjectURL(e.target.files[0]);
    let image = e.target.files[0];
    setSlides([{ image: uploadedImage }]);
    setIsSlideSet(true);

    const storageRef = ref(getStorage(app), "/images/" + phoneNumber);

    console.log(image);
    const uploadTask = uploadBytesResumable(storageRef, image);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        setPercentUploaded(progress);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const user_context = useContext(UserContext);
  const [userNameonPlanner, setUserNameonPlanner] =
    user_context.userNameonPlanner;

  const [nameOnPlanner, setNameOnPlanner] = useState("");

  const fileButton = document.getElementById("fileInput");
  return (
    <div className="step__five">
      <Header type="BLCH" />

      <div className="step__four__container">
        <div className="step_slider">
          <div className="back_icon" onClick={() => navigate(-1)}>
            <TPrev />
          </div>

          <div className="step_slider_info">
            <p className="tm">Step 5 of 5</p>
            <p className="ds">Image</p>
          </div>

          <div className="next_icon" onClick={(e) => checkInput()}>
            <TNext />
          </div>
        </div>

        <div className="Image__slider__box">
          <Loading step="6" />
          <ImageSlider Images={slides} />
        </div>

        <div className="inputs_container">
          <div className="input_file_box">
            <p className="hs align_left">Upload Your Image:</p>

           

            <progress max="100" value={percentUploaded}></progress>

            <div
              className="uploading_button"
              
            >
               <input
              type="file"
              id="fileInput"
              accept="image/*"
              onChange={(e) => {
                loadImage(e);
              }}
            />
              <Button type="shaded" title="Upload" icon="camera" />
            </div>

            <div className="types_banner">
              <p className="bm">
                This image gets printed on the first page. Ensure a good quality
                image for a good print
              </p>
            </div>
          </div>
          <div className="input_box_name">
            <p className="hs">* This planner belongs to: </p>
            <input
              required
              onChange={(e) => {
                setNameOnPlanner(e.target.value);
              }}
              type="text"
              name="name_on_planner"
              id="name_on_planner"
            />
          </div>
        </div>
      </div>

      <div className="step1__floatingButton" onClick={(e) => checkInput()}>
        <Button title="Next" icon="next" type="full" />
      </div>
      <GoToTop />
    </div>
  );
}

export default StepFive;
