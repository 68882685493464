import React, { useContext , useState } from "react";

import Header from "./Header";
import "./Review.css";
import Stepper from "./Stepper";
import Button from "./Button";
import GoToTop from "GoToTop";
import { ReactComponent as Edit2 } from "./svg/edit.svg";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as TPrev } from "./svg/arrow_left.svg";
import { UserContext } from "./context/UserContext";
import { CartContext } from "./context/CartContext";


import hero1 from './images/1.webp'
import hero2 from './images/2.webp'
import hero3 from './images/3.webp'

function Review() {

  let navigate = useNavigate();

  const [plannerPrice, setPlannerPrice] = useState(0+1);

  const user_context = useContext(UserContext);

  const[userAddonsPrice, setUserAddonsPrice] =user_context.userAddonsPrice;

  const [userTheme, setUserTheme] = user_context.userTheme;

  const [userQuote, setUserQuote] = user_context.userQuote;

  const [userPlannerType, setUserPlannerType] = user_context.userPlannerType;

  const [userPlannerPages, setUserPlannerPages] = user_context.userPlannerPages;

  const [userAddons, setUserAddons] = user_context.userAddons;

  const [cartState, cartDispatch] = useContext(CartContext);

  const [userPlannerPrice, setUserPlannerPrice] =user_context.userPlannerPrice;

  
  const [userPlannerImage, setUserPlannerImage] = useState(hero3)
  
 setUserAddonsPrice(userAddons.reduce((amount, item)=>item.price+amount, 0));
console.log("add on total",userAddonsPrice)

  const addToCart = () => {
    //console.log(userPlannerType);
    var totalPrice = userPlannerPrice + userAddonsPrice;
    console.log(userTheme)
     if(userTheme==="Evening Clouds") 
     {
      setUserPlannerImage(hero1)
      console.log("evening clouds imaget set")
     }
     else
     if(userTheme==="Deep Waves")
     {
       setUserPlannerImage(hero2)
     }
     else
     if(userTheme==="Sand Dunes")
     {
       setUserPlannerImage(hero3)
     }
    console.log(userPlannerImage);
    cartDispatch({
      type: "ADD_TO_CART",
      item: {
        id:"planner"+userPlannerType,
        type: "planner",
        name: userPlannerType,
        price: totalPrice,
        theme:userTheme,
        image: (userTheme==="Evening Clouds" )? hero1: (userTheme==="Deep Waves") ? hero2 :hero3,
        details: {
          userTheme,
          userQuote,
          userPlannerType,
          userPlannerPages,
          userAddons,
        },
      },
    });
    console.log("Cart has ", cartState);
  };

  return (
    
    <div className="review">
      <Header type="LCH" />

      <div className="step_slider">
          <div className="back_icon" onClick={() => navigate(-1)}>
            <TPrev />
          </div>

          <div className="step_slider_info" style={{"textAlign" : "right"}}>
            <p className="tm">Before adding this to cart</p>
            <p className="hm">Review</p>
          </div>

        </div>
      <div className="review__container">
     
       
        <div className="selection__info__box">
          <div className="info_name">
            <p className="bl">Planner Theme : </p>
            <Link to="/step1">
              <Edit2 />
            </Link>
          </div>
          <div className="info">
          <p className="tl">{userTheme}</p>
          </div>
        </div>
        

        <div className="selection__info__box">
          <div className="info_name">
            <p className="bl">Planner Quote : </p>
            <Link to="/step2">
              <Edit2 />
            </Link>
          </div>
          <div className="info">
            <p className="tl">"{userQuote}"</p>
          </div>
        </div>

        <div className="selection__info__box">
          <div className="info_name">
            <p className="bl">Planner Type : </p>
            <Link to="/step3">
              <Edit2 />
            </Link>
          </div>
          <div className="info">
            <p className="tl">{userPlannerType}</p>

            <div className="info_with_price">
              <p className="tl">{userPlannerPages.name}</p>
              <p className="tl">{userPlannerPages.price}</p>
            </div>
          </div>
        </div>

        <div className="selection__info__box">
          <div className="info_name">
            <p className="bl">Selected Addons : </p>
            <Link to="/step4">
              <Edit2 />
            </Link>
          </div>
          <div className="info">
            {userAddons === null && <p className="tl">None Selected</p>}

            {userAddons !== null &&
              userAddons.map((addOn, pos) => {
                /*setAddOnPrice(addOnPrice + addOn.price)*/
                return (
                  
                  <div className="info_with_price" key={pos}>
                    
                    <p className="ts">{addOn.name}</p>
                    <p className="lm">₹ {addOn.price}/-</p>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <div className="step1__floatingButton" onClick={(e) => addToCart()}>
        <Link to="/cart">
          <Button title="Add to Cart" icon="cart" type="full" />
        </Link>
      </div>
      <GoToTop/>
    </div>
  );
}

export default Review;
