import React from "react";
import "./index.css";
import "./Footer.css";
import { ReactComponent as Logo } from "./svg/WB_logo.svg";
import { ReactComponent as Facebook } from "./svg/facebook.svg";
import { ReactComponent as Whatsapp } from "./svg/whatsapp.svg";
import { ReactComponent as Instagram } from "./svg/instagram.svg";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <div className="Footer">
      <div className="footer__title">
        <Logo />
        <p className="small">Normalizing mental health</p>
      </div>
      <div className="footer__links">
        <a href="http://wa.me/917042336219" target="_blank" rel="noopener noreferrer">
          <Whatsapp />
        </a>
        <a href="http://instagram.com/wannabebawse" target="_blank" rel="noopener noreferrer">
          <Instagram />
        </a>
        <a href="https://www.facebook.com/wannabebawse" target="_blank" rel="noopener noreferrer">
          <Facebook />
        </a>
      </div>
      <div className="footer_descriptor">
        <h5>@2021.Wannabe Bawse Co.</h5>
        <p className="small">designed & developed by Mansukh & Nahush </p>
      </div>

      <div className="footer_policy_links">
        <div className="link_column">
        <Link to="/contactus">
          <p className="caption">Contact us</p>
          </Link>
          <Link to="/terms">
          <p className="caption">Terms of use</p>
          </Link>
        </div>
        <div className="link_column">
          <Link to="/privacy">
          <p className="caption">Privacy Policy</p>
          </Link>
          <Link to="/cancellation">
          <p className="caption">Returns & Exchange</p>
          </Link>
        </div>
        <div className="link_column">
          <Link to="/aboutus">
          <p className="caption">About Us</p>
          </Link>
          <p className="caption">Track your order</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
