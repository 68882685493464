import React, { useState, useContext } from "react";
import "./Cart.css";
import CartProduct from "./CartProduct";
import Header from "./Header";
import { CartContext } from "./context/CartContext";
import { useAuth } from "./context/AuthContext";
import { createOrder } from "./firebase";
import { getCartTotal } from "./reducer/CartReducer";
import { Link, useNavigate } from "react-router-dom";
import GoToTop from "GoToTop";
import { ReactComponent as TPrev } from "./svg/arrow_left.svg";
import Button from "Button";
function Cart() {
  const currentUser = useAuth()[0];
  console.log(currentUser.uid)
  const [{ cart }, cartDispatch] = useContext(CartContext);
  

let navigate=useNavigate();
  return (
    <div className="cart_page">
      <Header type="LCH" />
      
      <div className="step_slider">
          <div className="back_icon" onClick={() => navigate(-1)}>
            <TPrev />
          </div>

          <div className="step_slider_info" style={{"textAlign" : "right"}}>
            <p className="tm">Wow, this is my</p>
            <p className="hm">Shopping Cart</p>
          </div>

        </div>

      <div className="cart_page_container">
     

        <div className="cart_info">
          <p className="tl">In cart</p>
          <p className="tl">{cart.length} item(s) </p>
        </div>

        
    {console.log(cart)}
      <div className="cart__product__container">
      { cart.length !==0 ? cart.map((items, pos) => {
        return (
          <CartProduct
          id={items.id}
            type={items.type}
            name={items.name}
            qty={items.details.userAddons?items.details.userAddons.length : items.details.qty }
            price={items.type==="planner"?950:items.price}
            theme={items.theme}
            image={items.image}
            key={pos}
          />
       
        );

        
          
        

        
      }) : <p className="small">Cart is Empty</p> }





      {cart.length !==0 && cart.map((items, pos) => {
        {console.log(items.details.userAddons)}
      return(
        items.details.userAddons.map((addon, pos)=> {
          console.log(addon)
            return (
  
  
              <CartProduct
              id={addon.id}
              type={"addon"}
              name={addon.name}
              qty={1}
              price={addon.price}
              theme={""}
              image={"addon.image"}
              key={pos}
  
              />
            );
          })
      )
      
      })}
      </div>
      </div>

      <div className="checkout_button_container">
        <div className="cart_info">
          <p className="tl">Subtotal</p>
          <p className="tl">₹ {getCartTotal(cart)} </p>
        </div>
        
        <Link to="/checkout">
         
          <div className="checkout_button">
              <p className="tl">Checkout </p>
            
          </div>
          </Link>
        
      </div>
      <GoToTop/>
    </div>
  );
}

export default Cart;
