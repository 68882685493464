import Button from 'Button'
import React from 'react'
import Header from "./Header";
import { Link } from "react-router-dom";
import './StepZero.css'
import GoToTop from 'GoToTop';

import step1 from './images/3.webp'
import step2 from './images/2.webp'
import step3 from './images/clouds/3A1.webp'
import step4 from './images/waves/meal_planner.webp'
import step5 from './images/clouds/5A.webp'
function StepZero() {
    return (
        <div className='step__zero'>
            <h3>Customise Your Planner
            </h3>
            <Header type="BLCH" />
{/** -------------------------Step 1------------------------------------- */}  
            <div className="featuresSection">
          <img
            src={step1}
            alt=""
            className="featureImage"
          />
          <div className="featureDetails featureLeft">
            <p className="tl">Step 1</p>
            <p className="bl">
            Select a theme 
            </p>
          </div>
        </div>
        
 {/** -------------------------Step 2------------------------------------- */}        
        <div className="featuresSection">
        <img
            src={step2}
            alt=""
            className="featureImage"
          />
          <div className="featureDetails">
            <p className="tl">Step 2</p>
            <p className="bl">
            Select a quote
            </p>
          </div>
          
        </div>
        
  {/** -------------------------Step 3------------------------------------- */}          
        <div className="featuresSection">
          <img
            src={step3}
            alt=""
            className="featureImage"
          />
          <div className="featureDetails featureLeft">
            <p className="tl">Step 3</p>
            <p className="bl">
             Dated or Undated pages
            </p>
          </div>
        </div>
        
  {/** -------------------------Step 4------------------------------------- */}             
        <div className="featuresSection">
        <img
            src={step4}
             alt=""
            className="featureImage"
          />
          <div className="featureDetails">
            <p className="tl">Step 04</p>
            <p className="bl">
            Choose Addons
            </p>
          </div>
          
        </div>
        
   {/** -------------------------Step 5------------------------------------- */}         
        <div className="featuresSection">
          <img
            src={step5} 
            alt=""
            className="featureImage"
          />
          <div className="featureDetails featureLeft">
            <p className="tl">Step 05</p>
            <p className="bl">
             Add Your Image
             </p>
          </div>
        </div>

        <Link to="/step1">
        <Button title="Proceed" type="purple"/>
        </Link>

        <GoToTop/>
        </div>
    )
}

export default StepZero
