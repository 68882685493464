import React, {useContext} from "react";
import "./Checklist.css";
import { ChecklistOptionsContext } from "./context/ChecklistOptionsContext";
import { ReactComponent as Tick } from "./svg/checked.svg";



const userChoosed = [];




    

function Checklist(props) {

  const {chosen, setChosen} = useContext(ChecklistOptionsContext);

  const optSelected = (e, opt) =>{
    let elt=e.currentTarget.classList;
    e.preventDefault();
    if(elt.contains("option_selected"))
    {
        elt.remove("option_selected")
        e.currentTarget.querySelector("#tick_icon").classList.remove("show");
        for( var i = 0; i <userChoosed.length; i++){ 
    
          if ( userChoosed[i].id === opt.id) { 
      
              userChoosed.splice(i, 1); 
          }
        }
        
        console.log(userChoosed);
    }
    else
    {
    elt.add("option_selected");
    e.currentTarget.querySelector("#tick_icon").classList.add("show");
    userChoosed.push(opt);
    console.log(userChoosed);
    }

    setChosen(userChoosed);
    console.log("Chosen is : ",chosen);

  }


  

  return (
    <div className="checklist">
        
        
            

      {props.optionNames.map((option) => {
        return (
          <div className="checklist_option" key={option.id} onClick={event => optSelected(event,option)}>
              
            <div className="option_selection_bg">
              <div className="option_selector" id="tick_icon">
                <Tick />
              </div>
            </div>

            <p className="tm" id="option_name">{option.name}</p>
            <p className="ll">₹ {option.price}/-</p>
          </div>
        );
      })}
    </div>
  );
}

Checklist.defaultProps ={
  optionNames : [
    
    {id : 2, name : "Blank Note Pages", price : 30},
    {id : 3, name : "Finance Tracker", price : 30},
    {id : 4, name : "Fitness Tracker", price:30},
    {id : 5, name : "Meal Planner", price:85},
    {id : 6, name : "Productivity Planner", price:85},
    {id : 7, name : "Periods Tracker", price:0},
  ],
}

export default Checklist;
