import React from "react";
import "./ImageSlider.css";
import './CircularBanner.css'
import { ReactComponent as Left } from "./svg/caret_closed_left.svg";
import { ReactComponent as Right } from "./svg/caret_closed_right.svg";
import { ReactComponent as Share } from "./svg/share.svg";
import { ReactComponent as Zoom } from "./svg/magnifying_glass.svg";

import { useState } from "react";

import { useSwipeable } from "react-swipeable";
import ad from './images/Ad.webp'

import hero1 from './images/1.webp'
import hero2 from './images/2.webp'
import hero3 from './images/3.webp'


import Lottie from "react-lottie";
import ZoomLottie from "./lottie/pinch_zoom.json";


const style1={
        
  background: 'var(--blue)',
      color : 'black'
  }


const shareWbPlanner = async () => {
  const canonical = document.querySelector("link[rel=canonical]");
  let url = canonical ? canonical.href : document.location.href;
  const response = await fetch(ad);
  console.log(response)
  const blob = await response.blob();
  const filesArray = [
    new File(
      [blob],
      'WBPlanners.webp',
      {
        type: "image/webp",
        lastModified: new Date().getTime()
      }
   )
  ];
  console.log(filesArray)
  const shareData = {
    files: filesArray,
    title : "Wannabe Bawse 2022 Personalised Planners",
    text : " Bored of Basic Static Planners? Grab Wannabebawse 2022 DIY Ring Planners",
    url
  };
  if (navigator.share) {
    try {
      await navigator
        .share(shareData)
        .then(() =>
          console.log("Hooray! Your content was shared to tha world")
        );
    } catch (error) {
      console.log(`Oops! I couldn't share to the world because: ${error}`);
    }
  } else {
    // fallback code
    console.log(
      "Web share is currently not supported on this browser. Please provide a callback"
    );
  }

}

const showZoomPopup = () => {
  document.getElementById("zoom_icon").classList.remove("zoom_hide");
  document.getElementById("zoom_icon").classList.add("image_popup_container_zoom")
  setTimeout(() => {
    document.getElementById("zoom_icon").classList.add("zoom_hide");
    document.getElementById("zoom_icon").classList.remove("image_popup_container_zoom")
  }, 3000);

}



function ImageSlider(props) {
  const [currrent, setCurrent] = useState(0);
  const length = props.Images.length;
  const Slides = props.Images

  const popupBgOptions = {
    loop: true,
    autoplay: true,
    animationData: ZoomLottie,
    rendererSettings: {
      // preserveAspectRatio: "xMidYMid slice"
    },
  };

  
  

  const nextSlide = () => {
    setCurrent(currrent === length - 1 ? 0 : currrent + 1);
  };

  const prevSlide = () => {
    setCurrent(currrent === 0 ? length - 1 : currrent - 1);
  };
  

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => prevSlide(),
    onSwipedRight: () => nextSlide()
  })

  return (

    
    <div  {...swipeHandlers} className="SliderBox">
     
      <div className="leftArrow" onClick={prevSlide}>
        <Left />
      </div>
      <div className="zoom_hide" id="zoom_icon">
                <Lottie options={popupBgOptions} height={100} width={100} />
                <p className="ll">Pinch to Zoom</p>
            </div>

<div className="image_icon_box">
<div className="shareIcon" onClick={()=>shareWbPlanner()}>
        <Share />
</div>
<div className="zoomIcon"  onClick={()=>showZoomPopup()}>
  <Zoom/>
</div>
</div>
      

      {Slides.map((slide, index) => {
        
        return (
          <div
            className={index === currrent ? "slide active" : "slide"}
            key={index}
          >
            {index === currrent && (<img src={slide.image} alt="" />)}
          </div>
        );
      })}

         {props.first!==""&& <div className="priceBanner">
         <div className="circBanner" style={style1}>
         {props.first!==""? <p className="bm">Each @ </p> : <p className="bm"> <del>₹ 1600</del></p>}
         {props.second ==="" ? <p className="tl">₹ 950</p> : <p className="tl">₹ {props.second} </p>}
              
               
            </div>
          </div> 
}
      <div className="sliderIndicatorBox">
            {
                Array.from({length : length}).map((item,index) =>(
                    <div key={index} className={index <= currrent ? "dot active" : "dot"}></div>
                ))
            }

      </div>

      <div className="rightArrow" onClick={nextSlide}>
        <Right />
      </div>
    </div>
  );
}

ImageSlider.defaultProps={
  Images : [
    
    { image: hero1 },
    { image: hero2 },
    { image: hero3 },
    
  ],
  first : "",
  second: ""
}

export default ImageSlider;
