import React, {useContext} from 'react'
import {ReactComponent as Logo} from './svg/WB_logo.svg'
import {ReactComponent as Hamburger} from './svg/hamburger.svg'
import {ReactComponent as Cart} from './svg/cart.svg'
import { Link, useNavigate} from 'react-router-dom'
import './Header.css'
import { getCartProductNames } from "./reducer/CartReducer";
import { CartContext } from "./context/CartContext";


function Header(props) {

    const [{ cart }, cartDispatch] = useContext(CartContext);


    const navigate = useNavigate();
    if (props.type==="LUH")
    {
    return (
        <div className="Header">
            <Link to="/">
            <div className="Header__LogoBox">
                <Logo/>
            </div>
            </Link>
            <div className="Header__IconBox">
                
                <Link to="/cart">
                <Cart/>
                </Link>
                <Link to="/menu">
                <Hamburger/>
                </Link>
            </div>
        </div>
    )
    }

    else 
    if (props.type==="LCH")
    {
        return (
            <div className="Header">
                
           <Link to="/">
            <div className="Header__LogoBox">
                <Logo/>
            </div>
            </Link>
            <div className="Header__IconBox">
            <div className="cart_icon_box">
            <p className="ll" id="cartItemsQty">{getCartProductNames(cart).length}</p>
            <Link to="/cart">
                <Cart/>
                </Link>
                </div>
                <Link to="/menu">
                <Hamburger/>
                </Link>
            </div>
        </div>   
        )
    }

    else 
    if (props.type==="BLCH")
    {
        return(
            <div className="Header">
            
            <Link to="/">
            <div className="Header__LogoBox">
                <Logo/>
            </div>
            </Link>
            <div className="Header__IconBox">
            <div className="cart_icon_box">
            <p className="caption" id="cartItemsQty">{getCartProductNames(cart).length}</p>
            <Link to="/cart">
                <Cart/>
                </Link>
                </div>
                
                <Link to="/menu">
                <Hamburger/>
                </Link>
            </div>
        </div>   
        )
    }

    else 
    if (props.type==="BLX")
    {
       return(
        <div className="Header">
        
        <Link to="/">
            <div className="Header__LogoBox">
                <Logo/>
            </div>
            </Link>
        <div className="Header__IconBox">
        <Link to="/cart">
                <Cart/>
                </Link>
            <Link to="/menu">
                <Hamburger/>
                </Link>
        </div>
    </div>   
       )
    }
}

export default Header
