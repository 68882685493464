import React, { useState, useContext } from "react";

import Header from "./Header";
import Loading from "./Loading";
import ImageSlider from "./ImageSlider";
import Button from "./Button";
import "./StepThree.css";
import DetailsSlider from "./DetailsSlider";
import { ImageContext } from "./context/ImageContext";
import { UserContext } from "./context/UserContext";
import { DropdownOptionContext } from "./context/DropdownOptionContext";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "./Dropdown";
import GoToTop from "GoToTop";

import { ReactComponent as TPrev } from "./svg/arrow_left.svg";
import { ReactComponent as TNext } from "./svg/arrow_right.svg";

import theme1_4 from "./images/clouds/3A1.webp";
import theme1_5 from "./images/clouds/3A2.webp";

import theme2_4 from "./images/dunes/3C1.webp";
import theme2_5 from "./images/dunes/3C2.webp";

import theme3_4 from "./images/waves/3B1.webp";
import theme3_5 from "./images/waves/3B2.webp";
import RadioButton from "RadioButton";
import { RadioOptionContext } from "context/RadioContext";

const Planner1 = [
  {
    image: theme1_4,
  },
  {
    image: theme2_4,
  },
  {
    image: theme3_4,
  },
];

const Planner2 = [
  {
    image: theme1_5,
  },
  {
    image: theme2_5,
  },
  {
    image: theme3_5,
  },
];

const Planners = [
  {
    name: "Dated Pages",
    writeup:
      ["Dates numbered","3 days on every page","Pages for 12 months" ],
    serial: Planner1,
  },

  {
    name: "Undated Pages",
    writeup:
     [ "You write the dates","Single day pages","Pages for 6 months"],
      serial: Planner2,
  },
];

function StepThree() {
  const user_context = useContext(UserContext);

  console.log(user_context.userPlannerType);

  const [userPlannerType, setUserPlannerType] = user_context.userPlannerType;

  const [userPlannerPages, setUserPlannerPages] = user_context.userPlannerPages;

  const [userPlannerPrice, setUserPlannerPrice] = user_context.userPlannerPrice;

  const [mode, setMode] = useState(0);

  const [option, setOption] = useState("");

  const [slides, setSlides] = useState(Planner1);

  const options = ["Dated pages for 12 months", "Undated pages for 6 months"];

  const changeMode = (e, id, value) => {
    e.preventDefault();
    setUserPlannerPages(value);
    console.log(userPlannerType);
    console.log(userPlannerPages);
    setMode(id);
    console.log(mode);
  };

  let navigate = useNavigate();
  const checkInput = (e) => {
    if (option === "") {
      alert("Kindly Select an Option for planner type to Proceed Further! ");
    } else navigate("/step4");
  };

  return (
    <div className="step__three">
      <Header type="BLCH" />

      <div className="step__three__container">
      

        <div className="step_slider">
          <div className="back_icon" onClick={() => navigate(-1)}>
            <TPrev />
          </div>
          <div className="step_slider_info">
            <p className="tm">Step 3 of 5</p>
            <p className="hl">Type</p>
          </div>
          <div className="next_icon" onClick={(e) => checkInput(e)}>
            <TNext />
          </div>
        </div>

        <div className="background">
          <div className="image_details_slider_container">
            <div className="Image__slider__box">
              <Loading step="3" />
              <ImageSlider Images={slides} />
            </div>
            <ImageContext.Provider value={{ slides, setSlides }}>
              <DetailsSlider Details={Planners} />
            </ImageContext.Provider>

          
            
          </div>
        </div>
      </div>

      <div className="option__selector">
        <p className="hs left_align">Your selection:</p>
        <RadioOptionContext.Provider value={{ option, setOption }}>
          <RadioButton options={options} />
        </RadioOptionContext.Provider>
        {setUserPlannerType(option)}
            {option !== "" && setUserPlannerPrice(950)}
            {console.log(userPlannerPrice)}

        <div className="types_banner">
            <p className="bl"> <b>Undated page bundle</b>  is now available in the addons to complete a full year</p>
          </div>
      </div>
      {setUserPlannerType(option)}
      {option !== "" && setUserPlannerPrice(950)}
      {console.log(userPlannerPrice)}
      <div className="step1__floatingButton" onClick={(e) => checkInput(e)}>
        <Button title="Next" icon="next" type="full" />
      </div>
      <GoToTop />
    </div>
  );
}

export default StepThree;
