import React, { useState, useContext } from "react";
import Header from "./Header";
import Loading from "./Loading";
import ImageSlider from "./ImageSlider";
import Button from "./Button";
import DetailsSlider from "./DetailsSlider";
import "./StepOne.css";
import { ImageContext } from "./context/ImageContext";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "./context/UserContext";
import GoToTop from "GoToTop";
import { ReactComponent as TPrev } from "./svg/arrow_left.svg";
import { ReactComponent as TNext } from "./svg/arrow_right.svg";

import theme1Cover from './images/1.webp'
import theme2Cover from './images/2.webp'
import theme3Cover from './images/3.webp'

import theme1_1 from './images/clouds/1A.webp'
import theme1_2 from './images/clouds/finance_tracker.webp'
import theme1_3 from './images/clouds/ikigai.webp'
import theme1_4 from './images/clouds/3A1.webp'
import theme1_5 from './images/clouds/3A2.webp'
import theme1_6 from './images/clouds/meal_planner.webp'
import theme1_7 from './images/clouds/month_end.webp'
import theme1_8 from './images/clouds/5A.webp'

import theme2_1 from './images/dunes/1C.webp'
import theme2_2 from './images/dunes/month_cover.webp'
import theme2_3 from './images/dunes/ikigai.webp'
import theme2_4 from './images/dunes/3C1.webp'
import theme2_5 from './images/dunes/3C2.webp'
import theme2_6 from './images/dunes/meal.webp'
import theme2_7 from './images/dunes/df.webp'
import theme2_8 from './images/dunes/6C.webp'

import theme3_1 from './images/waves/1B.webp'
import theme3_2 from './images/waves/month_start.webp'
import theme3_3 from './images/waves/ikigai.webp'
import theme3_4 from './images/waves/3B1.webp'
import theme3_5 from './images/waves/3B2.webp'
import theme3_6 from './images/waves/meal_planner.webp'
import theme3_7 from './images/waves/cvb.webp'
import theme3_8 from './images/waves/6B.webp'


import Lottie from "react-lottie";
import Swipe from "./lottie/swipe.json";
import PinchZoom from "./lottie/pinch_zoom.json";
import RadioButton from "RadioButton";
import { RadioOptionContext } from "context/RadioContext";

const Theme1 = [
  {
    image:theme1Cover
  },
  {
    image:theme1_1
  },
  {
    image:theme1_2
  },
  {
    image:theme1_3
  },
  {
    image:theme1_4
  },
  {
    image:theme1_5
  },
  {
    image:theme1_6
  },
  {
    image:theme1_7
  },
  {
    image:theme1_8
  },
  
];

const Theme2 = [
  {
    image:theme3Cover
  },
  {
    image:theme2_1
  },
  {
    image:theme2_2
  },
  {
    image:theme2_3
  },
  {
    image:theme2_4
  },
  {
    image:theme2_5
  },
  {
    image:theme2_6
  },
  {
    image:theme2_7
  },
  {
    image:theme2_8
  },
  
];

const Theme3 = [
  {
    image:theme2Cover
  },
  {
    image:theme3_1
  },
  {
    image:theme3_2
  },
  {
    image:theme3_3
  },
  {
    image:theme3_4
  },
  {
    image:theme3_5
  },
  {
    image:theme3_6
  },
  {
    image:theme3_7
  },
  {
    image:theme3_8
  },
  
];

export const Themes = [
  {
    name: "Evening Clouds",
    writeup:"For those who want to relax, watching the evening clouds pass by, just doing nothing & having those still moments where you connect with the skies.",
    serial: Theme1,
  },

  {
    name: "Deep Waves",
    writeup:"For those who wants to be near the waves passing by, on some beach, salt & sand between your toes, & the moments where you connect with the waves.",
    serial: Theme3,
  },
  {
    name: "Sand Dunes",
    writeup: "For those who wants to enjoy warm sunny days, with clear skies & clear headspace. We know that some sunshine is always good for the soul.",  
       serial: Theme2,
  },
];

function StepOne() {

  let navigate = useNavigate();
  const checkInput = (e) => {
    if (userTheme === "") {
      alert(
        "Kindly Select a Theme for your planner"
      );
    } else navigate("/step2");
  };

  const user_context = useContext(UserContext);
  console.log(user_context.userTheme);

  const [userTheme, setUserTheme] = user_context.userTheme;

  {/**const [mode, setMode] = useState(1);

  const changeMode = (e, id, value) => {
    setUserTheme(value);
    setMode(id);
    console.log(mode);
    console.log(userTheme);
  };*/}

  const [slides, setSlides] = useState(Theme1);

  const [option, setOption] = useState("");
  

  
  return (
    <div className="step__one">
      <Header type="BLCH" />

      <div className="step__one__container">
       
        <div className="step_slider">
        <div className="back_icon" onClick={() => navigate(-1)}>
             <TPrev />
          </div>
          <div className="step_slider_info">
              <p className="tm">Step 1 of 5</p>
              <p className="hl">Theme</p>
          </div>
         
          <div className="next_icon" onClick={(e) => navigate("/step2")}>
          <TNext/>
          </div>
        </div>

        <div className="background">
        <div className="image_details_slider_container">
          <div className="Image__slider__box">
            <Loading step="1" />
            <ImageSlider Images={slides} />
          </div>

          <ImageContext.Provider value={{ slides, setSlides }}>
            <DetailsSlider Details={Themes} />
          </ImageContext.Provider>
        </div>
        </div>
         

<div className="option__selector">
  <p className="hs left_align">Your selection:</p>
  <RadioOptionContext.Provider value={{option, setOption}}>
    <RadioButton/>
  </RadioOptionContext.Provider>
  {console.log(option)}
  {setUserTheme(option)}
  {console.log(userTheme)}
  
</div>
        
      </div>

      <div className="step1__floatingButton" onClick={(e) => checkInput(e)}>
        
          <Button title="Next" icon="next" type="full" />
        
      </div>
      <GoToTop />
    </div>
  );
  
  
}

export default StepOne;
