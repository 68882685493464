import React from "react";
import "./DetailsSlider.css";
import { ReactComponent as CaretLeft } from "./svg/caret_left.svg";
import { ReactComponent as CaretRight } from "./svg/caret_right.svg";
import PropTypes from 'prop-types'
import { ImageContext } from "./context/ImageContext";
import { useState, useContext } from "react";
import "./index.css";

import {ReactComponent as TPrev } from './svg/caret_left.svg'
import {ReactComponent as TNext } from './svg/caret_right.svg'

function DetailsSlider(props) {

  const {slides, setSlides} =  useContext(ImageContext);
  console.log("image context is", useContext(ImageContext))
  
  const [currrent, setCurrent] = useState(0);
  const length = props.Details.length;

  const nextSlide = () => {
    setCurrent(currrent === length - 1 ? 0 : currrent + 1);
    //setSlides(props.Details[currrent].serial);
    console.log(currrent)
  };

  const prevSlide = () => {
    setCurrent(currrent === 0 ? length - 1 : currrent - 1);
    //setSlides(props.Details[currrent].serial);
    console.log(currrent)
  };
  return (
    <div className="DetailsSlider">
      {props.Details.map((slide, index) => {
        return (
          <div className="details_slider_container">
            {index === currrent && (
              <>
               
                <div className="topBoxContainer">
                  <div className="DetailsSlider_leftArrow" onClick={prevSlide}>
                    <TPrev />
                  </div>
                  {
                 slides!=="" && setSlides(slide.serial)}
                  <div className="topBox">
                    <p className="hl" style={{"textAlign":"center"}}>{slide.name}</p>
                    
                    
                  </div>

                  <div className="DetailsSlider_rightArrow" onClick={nextSlide}>
                    <TNext />
                  </div>
                </div>

                { typeof(slide.price)!=="undefined" && <div className="price_page_info">
                  <p className="hs">₹ {slide.price}</p>
                  <p className="hs">{slide.pages} pages</p>
                </div>}
               

                <div className="infoBox">
                  { typeof(slide.writeup)!=="object"  ? <p className="bl">{slide.writeup}</p> : 
                  <ul>
                    {slide.writeup.map((inf,pos)=>{ return(<li key={pos}>{inf}</li>)})}
                    </ul>}
                </div>

               
              </>
            )}
          </div>
        );
      })}
    </div>
  );
}

DetailsSlider.propTypes = {
  Details : PropTypes.array
}

DetailsSlider.defaultProps = {
  Details : [
    {
      name: "Self Care Journal",
      writeup:
        "This pack has this thing and you will be able to do this with it. This thing and you will do this with it nicely.",
        serial:[{
          image:
            "https://images.unsplash.com/photo-1525268771113-32d9e9021a97?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cGFzdGVsfGVufDB8MnwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
        }],
        price:"",
        pages:""
    },
    {
      name: "Fitness Planner",
      writeup:
        "This pack has that thing and you will be able to do this with it. This thing and you will do this with it nicely.",
        serial:[{
          image:
            "https://images.unsplash.com/photo-1525268771113-32d9e9021a97?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cGFzdGVsfGVufDB8MnwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
        }]
    },
    
    
    
    
  ]
}

export default DetailsSlider;
