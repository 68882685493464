import React, { useContext, useEffect } from "react";
import "./Checkout.css";
import Header from "./Header";
import Stepper from "./Stepper";
import Button from "./Button";
import { useState } from "react";
import { ReactComponent as Edit2 } from "./svg/edit.svg";

import { ReactComponent as TPrev } from "./svg/arrow_left.svg";
import { CartContext } from "./context/CartContext";
import { getCartTotal, getCartProductNames } from "./reducer/CartReducer";
import { currentOrderIdRef, createOrder, createCustomer } from "./firebase";
import { getDocs, onSnapshot } from "@firebase/firestore";
import { useAuth } from "./context/AuthContext";
import GoToTop from "GoToTop";
import {useNavigate} from "react-router-dom";
//import {Razorpay} from "razorpay";
function Checkout() {

  const navigate=useNavigate();
  const [curOrder, setcurOrder] = useState(0);

  //Customer Details
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState(0);
  const [city, setCity] = useState("");
  const [adState, setAdState] = useState("");

  //Order Amount Details
  const [discountCode, setDiscountCode] = useState("");
  const [orderNumber, setOrderNumber] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [shipping, setShipping] = useState(100);
  const [amountToBePaid, setAmountToBePaid] = useState(0);

  useEffect(() => {
    const unsub = onSnapshot(currentOrderIdRef, (snap) => {
      setcurOrder(snap.docs[0].data().OrderID);
    });

    return unsub;
  }, []);
  const [{ cart }, cartDispatch] = useContext(CartContext);
  console.log("Last Order Id is", curOrder);

  const couponCodes = [
    { code: "EARLYBIRD", discount: 60 },
    { code: "DELHINCR", discount: 100 },
    { code: "delhincr", discount: 100 },
    { code: "Delhincr", discount: 100 },
    { code: "DelhiNCR", discount: 100 },
  ];
  

  var delhi = [];
  for (var i = 110001; i <= 110096; i++) {
      delhi.push(i);
  }
  var ncr = [
    122001, 122002, 122003, 122004, 122006, 122007, 122008, 122009, 122010,
    122011, 122015, 122016, 122017, 122018, 122051, 122052, 122101, 122102,
    122103, 122104, 122105, 122107, 122108, 122413, 122414, 122502, 122503,
    122504, 122505, 122506, 122508, 123106, 123401, 203207, 201310, 201313,
    203202, 203203, 203155, 201304, 203209, 203141, 201008, 201306, 201314,
    201307, 201311, 203135, 203201, 201312, 201309, 201305, 201301, 201303,
    203202, 203203, 203155, 203209, 203141, 203135, 203201, 203207, 201310,
    201313, 201304, 201008, 201306, 201314, 201307, 201311, 201312, 201309,
    201305, 201301, 201303, 201206, 245101, 201015, 245208, 245201, 245304,
    201009, 201001, 245205, 201003, 201102, 245207, 201204, 201010, 201005,
    201011, 201006, 201201, 201016, 245301, 201013, 201004, 201002, 201012,
    201014, 201007, 201301, 201303, 201017, 201103, 201019,
  ];
  const calcDiscount = () => {
   
    couponCodes.forEach((codes) => {
      console.log("discount code entered",discountCode)
      console.log(codes.code, codes.discount, discountCode);

      var pincodesSet=delhi.concat(ncr)
      console.log(pincodesSet)
      
      if (discountCode === codes.code) {
        if (discountCode === "delhincr" || discountCode === "DELHINCR" ) {
          console.log(typeof(pincode))
          if (pincodesSet.includes(parseInt(pincode))) {
              setShipping(0)
          }
          else alert("Discount Code is only Applicable for Delhi NCR Residents (Delhi, Noida, Gurugram, Faridabad, Ghaziabad)")
        } else setDiscount(codes.discount);
      } else setDiscount(0);
      console.log("discount is", discount);
    });
  };

  const calcShipping = () => {
    console.log(getCartTotal(cart), typeof(getCartTotal(cart)))
    if(getCartTotal(cart)>=1000)
    {
      setShipping(0);
    }
    console.log("shipping is", shipping);
  };

  const calcAmountToBePaid = () => {
    setAmountToBePaid(getCartTotal(cart) + shipping - discount);

    console.log("amnt to be paid", amountToBePaid);
  };

  const goToStep1 = () => {
    let pages = document.getElementsByClassName("checkout_page");
    for (var i = 0, len = pages.length | 0; i < len; i = (i + 1) | 0) {
      if (!pages[i].classList.contains("hide")) {
        pages[i].classList.add("hide");
      }
    }
    document.getElementById("checkout_page_1").classList.remove("hide");
  };

  const goToStep2 = () => {
    calcDiscount();
    calcShipping();

    let pages = document.getElementsByClassName("checkout_page");
    for (var i = 0, len = pages.length | 0; i < len; i = (i + 1) | 0) {
      if (!pages[i].classList.contains("hide")) {
        pages[i].classList.add("hide");
      }
    }
    document.getElementById("checkout_page_2").classList.remove("hide");

    calcAmountToBePaid();
  };

  const goToStep3 = () => {
    let pages = document.getElementsByClassName("checkout_page");
    for (var i = 0, len = pages.length | 0; i < len; i = (i + 1) | 0) {
      if (!pages[i].classList.contains("hide")) {
        pages[i].classList.add("hide");
      }
    }
    document.getElementById("checkout_page_3").classList.remove("hide");
  };

  const userID = useAuth()[0].uid;
  const userPhoneNumber = useAuth()[0].phoneNumber;
  const user = useAuth()[0];

  const [userName, setUserName] = useAuth()[1].userName;

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;

      script.onload = () => {
        resolve(true);
      };

      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  };

  const displayPaymentGateway = async (amount) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("You are offline...Failed to load");
      return;
    }

    //var instance = new Razorpay({  key_id: 'rzp_test_3fGfI7JjfHLHwV',  key_secret: 'aVsbalYXl3bc1iu1M9hzf8Nn'})
    //let MyOrder = instance.orders.create({amount, currency:"INR" ,  orderNumber})
    //console.log(MyOrder)

    const options = {
      key: `${process.env.REACT_APP_RAZORPAY_KEY}`,
      currency: "INR",
      amount: amount * 100,
      name: "Wannabe Bawse Shop",

      handler: function (response) {
        const customerPaymentDetails = {
          uid: userID,
          Name: fname + " " + lname,
          Address: { address, city, adState, pincode },
          createdAt: new Date(),
          Mobile: mobile,
          OrderID: orderNumber,
          AmountPaid: amount,
          PaymentID: response.razorpay_payment_id,
        };
        //alert( response.razorpay_payment_signature)
        createCustomer(user, customerPaymentDetails);
        navigate("/paymentdone")
      },

      prefill: { contact: userPhoneNumber },

      theme: { color: "#6A4D86" },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const getOrderID = () => {
    setOrderNumber(curOrder + 1);
    const orderDetails = {
      uid: userID,
      Name: fname + " " + lname,
      Username: userName,
      Coupon: discountCode,
      DiscountAmount: discount,
      OrderID: orderNumber,
      ProductNames: getCartProductNames(cart),
      ShippingAmount: shipping,
      TotalAmountPaid: amountToBePaid,
      TotalCartValue: getCartTotal(cart),
      TotalProductsOrdered: getCartProductNames(cart).length,
      createdAt: new Date(),
      orderStatus: "PLACED",
      
      orderSpecification: { cart },
    };

    console.log(orderDetails);
    createOrder(user, orderDetails);
    
  };

  return (
    <div className="checkout">
      <Header type="BLCH" />

      <div className="step_slider">
          <div className="back_icon" onClick={() => navigate(-1)}>
            <TPrev />
          </div>

          <div className="step_slider_info" style={{"textAlign" : "right"}}>
            <p className="tm">Ooo, finaly!</p>
            <p className="hm">Checkout</p>
          </div>

        </div>


      <div className="checkout_page" id="checkout_page_1">
     
        <p className="hl">Receiver:</p>

        <div className="input_box">
          <p className="small">First name</p>
          <input
            required
            onChange={(e) => {
              setFname(e.target.value);
            }}
            type="text"
            name="fname"
            id="fname"
          />
        </div>

        <div className="input_box">
          <p className="small">Last name</p>
          <input
            required
            onChange={(e) => {
              setLname(e.target.value);
            }}
            type="text"
            name="lname"
            id="lname"
          />
        </div>

        <div className="input_box">
          <p className="small">Mobile Number</p>
          <input
            required
            onChange={(e) => {
              setMobile(e.target.value);
            }}
            type="number"
            max="9999999999"
            min="1000000000"
            name="mobile"
            id="mobile"
          />
        </div>

        <div className="divider"></div>
        <p className="hl">Delivery:</p>
        <div className="input_box">
          <p className="small">Shipping address</p>
          <textarea
            required
            onChange={(e) => {
              setAddress(e.target.value);
            }}
            type="text"
            name="address"
            id="address"
          />
        </div>

        <div className="input_box">
          <p className="small">Pin Code</p>
          <input
            required
            onChange={(e) => {
              setPincode(e.target.value);
            }}
            type="number"
            max="999999"
            min="100000"
            name="pincode"
            id="pincode"
          />
        </div>

        <div className="input_box">
          <p className="small">City</p>
          <input
            required
            onChange={(e) => {
              setCity(e.target.value);
            }}
            type="text"
            name="city"
            id="city"
          />
        </div>

        <div className="input_box">
          <p className="small">State</p>
          <input
            required
            onChange={(e) => {
              setAdState(e.target.value);
            }}
            type="text"
            name="state"
            id="state"
          />
        </div>

        <div
          className="floatingButton"
          onClick={(e) => {
            if (
              fname === "" ||
              lname === "" ||
              mobile === "" ||
              address === "" ||
              pincode === "" ||
              adState === "" ||
              city === ""
            ) {
              alert("Kindly Fill All Details");
            } else {
              goToStep2();
            }
          }}
        >
          <Button title="Proceed" icon="next" type="full" />
        </div>
      </div>

      <div className="checkout_page hide" id="checkout_page_2">
       
        <div className="receiver_details_preview">
          <div
            className="details_title"
            onClick={(e) => {
              goToStep1();
            }}
          >
            
          <p className="hs">Delivering to</p>
            <Edit2 />
          </div>
          <div className="receiver_details">

            <div className="receiver_details_row">
              <p className="ll">Receiver</p>
              <p className="bm">{fname} {lname} </p>
            </div>

            <div className="receiver_details_row">
              <p className="ll">Contact</p>
              <p className="bm">{mobile} </p>
            </div>

            <div className="receiver_details_row">
              <p className="ll">Address</p>
              <p className="bm">{address}</p>
            </div>

            <div className="receiver_details_row">
              <p className="ll">City</p>
              <p className="bm">{city}</p>
            </div>

            <div className="receiver_details_row">
              <p className="ll">State</p>
              <p className="bm">{adState}</p>
            </div>

            <div className="receiver_details_row">
              <p className="ll">Pincode</p>
              <p className="bm">{pincode}</p>
            </div>
          </div>
        </div>

        <div className="coupon_section_container">
          <p className="hs">Apply Discount Code</p>
          
          <div className="coupon_section">
            <input
              type="text"
              id="coupon_code"
              placeholder="Enter Coupon Code"
              onChange={(e) => {
                setDiscountCode(e.target.value);
              }}
            />
            <div
              className="apply_button"
              onClick={(e) => {
                calcDiscount();
              }}
            >
              <Button title="Apply" type="shaded" />
            </div>
          </div>
        </div>

        <div className="order_summary_container">
        
              <p className="hs">Order summary</p>
          <div className="order_summary">
            <div className="summary_row">
              <p className="ll">Cart subtotal</p>
              <p className="bl"> {getCartTotal(cart)}</p>
            </div>
           
            <div className="summary_row">
              <p className="ll">Shipping</p>
              <p className="bl">{shipping}</p>
            </div>
            
            <div className="divider"></div>
            <div className="summary_row">
              <p className="tl">Order total</p>
              <p className="tl">{getCartTotal(cart) + shipping - discount}</p>
              
            </div>
          </div>
        </div>

        <div
          className="floatingButton"
          onClick={(e) => {
            getOrderID();

            displayPaymentGateway(getCartTotal(cart) + shipping - discount);
          }}
        >
          <Button title="Proceed to Pay" icon="next" type="full" />
        </div>
      </div>

      <div className="checkout_page hide" id="checkout_page_3">
        
      </div>
      <GoToTop />
    </div>
  );
}

export default Checkout;
