import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getAuth, RecaptchaVerifier } from "firebase/auth";

import {getFirestore, getDoc, onSnapshot, getDocs, doc, setDoc, collection, query, orderBy, limit} from '@firebase/firestore';

const app = initializeApp({
  apiKey: "AIzaSyAWUJG1ycTBbepxn028V6E4K1N1segH_E4",
  authDomain: "planners-wannabe-bawse.firebaseapp.com",
  projectId: "planners-wannabe-bawse",
  storageBucket: "planners-wannabe-bawse.appspot.com",
  messagingSenderId: "289671319042",
  appId: "1:289671319042:web:968b982947c94885e8a11b"
})



export const auth = getAuth();
export default app;

export const db = getFirestore(app);

const ordersRef = collection(db, "orders")
export const currentOrderIdRef = query(ordersRef, orderBy("OrderID","desc"),limit(1));



export const createUserDocument = async (user, fname, lname) =>{
  if(!user) return;
  console.log(user);
  const userRef = collection(db, "users")
  console.log("Users are", await getDocs(userRef));
  const userDoc = await getDoc(doc(db,'users',user.uid));
  console.log(userDoc)
  
  //const snapshot = await userRef.get();

  if (!userDoc.exists()) {
    console.log("not true")
    try {
      await setDoc( doc(userRef,user.uid) ,{
        Fname : fname,
        Lname : lname,
        Mobile : user.phoneNumber,
      })
       console.log("User Created");
    }
    catch(error){
      console.log('Error in creating user', error);
    }
  }
}

export const createOrder = async (user, orderDetails) => {
  if(!user) return;
  console.log(user);
  const ordersRef = collection(db, "orders")
  console.log("Orders are", await getDocs(ordersRef));

  const orderDocName = " Order for " + orderDetails.Name
  try {
    await setDoc( doc(ordersRef, orderDocName) ,orderDetails)
     console.log("Order Created");
  }
  catch(error){
    console.log('Error in creating order', error);
  }

}

export const createCustomer = async (user, customerPaymentDetails) => {
  if(!user) return;
  console.log(user);
  const customersRef = collection(db, "customers")
  console.log("Orders are", await getDocs(customersRef));

  const customerDocName = " Order Number " + customerPaymentDetails.OrderID + " for " + customerPaymentDetails.Name
  try {
    await setDoc( doc(customersRef, customerDocName) ,customerPaymentDetails)
     console.log("Customer Created");
  }
  catch(error){
    console.log('Error in creating customer', error);
  }
}

