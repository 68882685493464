import React, { useContext } from "react";
import "./Dropdown.css";
import { ReactComponent as CaretDown } from "./svg/caret_left.svg";
import { DropdownOptionContext } from "./context/DropdownOptionContext";


const showDropdown = () => {
  document.getElementById("dropbox").classList.add("show");
}

const hideDropdown = () =>  {
  document.getElementById("dropbox").classList.remove("show");
}

const onSelect = (e,v) =>  {
  e.preventDefault();
  let allOptions=document.getElementsByClassName("option");
  for (var i=0, len=allOptions.length|0; i<len; i=i+1|0)
  {
    allOptions[i].classList.remove("choosed");
  }
  e.currentTarget.classList.add("choosed");
  console.log(v);
  document.getElementById("text").innerText = v;
  document.getElementById("drop").classList.add("selected");
  hideDropdown();
}

function Dropdown(props) {

  const {Option, setOption}=useContext(DropdownOptionContext);
  return (
    
    <div className="dropdown">
     
      <div className="dropdown_selected" id="drop" onClick={showDropdown}>
        <p className="large" id="text">
          Select One
        </p>
        <CaretDown/>
      </div>

      <div className="options" id="dropbox">
      {props.optionNames.map((option,pos) => {
        return(
        
          <div className="option" key={pos} onClick={event => {onSelect(event, option); setOption(option); console.log(Option)}}>
            <p className="large">{option}</p>
          </div>
       
      )})}
       </div>
    </div>
  );
}

Dropdown.defaultProps = {
  optionNames : [
    "Yearly Dated Planner", "Undated Daily Planner"
    ],
    
};

export default Dropdown;
