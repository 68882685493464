import React, {useContext} from "react";
import "./ExtrasCard.css";
import { ReactComponent as CartBasic } from "./svg/cart.svg";
import { CartContext } from "./context/CartContext";

function ExtrasCard(props) {

  const [cartState, cartDispatch] = useContext(CartContext);

  const addExToCart = () => {
    cartDispatch({
      type: "ADD_TO_CART",
      item: {
        id:props.type+props.name,
        type: props.type,
        name: props.name,
        price: props.price,
        image: "nothing",
        details: {
          qty : props.qty,
          theme : props.theme,
        },
      },
    });
    console.log("Cart has ", cartState);
  };


  return (
    <div className="extras__card">
      <div className="ec_img_container">
        <img
          className=" ec_img"
          src={props.img}
          alt=""
        />
      </div>

<div className="ec_price_tag">
  
  <p className="buttonLarge">₹ {props.price}</p>
</div>
     <div className="ec_details_container">
     <div className="ec_details">
        <h5>{props.name}</h5>

        <p className="medium"> {props.theme} </p>

        <p className="caption">Qty {props.qty}</p>
      </div>
      <div className="ec_button" onClick={(e) =>addExToCart()}>
        <p className="buttonLarge">Add</p>
        <CartBasic/>
      </div>
     </div>
    </div>
  );
}

ExtrasCard.defaultProps= {

  type : "extra",
  name: "Sword Pens",
  price: 10,
  qty: 3,
  theme: "Anime" ,
  img: "https://images.unsplash.com/photo-1562247527-8b6db642ee3b?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDV8fHN0YXRpb25hcnl8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",

}
export default ExtrasCard;


