import React from 'react'
import Header from "./Header";
import Footer from "Footer";
import { ReactComponent as Facebook } from "./svg/facebook.svg";
import { ReactComponent as Whatsapp } from "./svg/whatsapp.svg";
import { ReactComponent as Instagram } from "./svg/instagram.svg";

import './Policy.css'

function ContactUs() {
    return (
        
        <div className='contact_us_page'>
            <Header type="BLCH" />
            <h3>Reach Out To Us At</h3>
            <div className="contact_type">
                <p className="medium">
                    WhatsApp : +917042336219
                </p>
                <a href="http://wa.me/917042336219" target="_blank" rel="noopener noreferrer">
          <Whatsapp />
        </a>
            </div>

            <div className="contact_type">
                <p className="buttonMedium">
                   Instgram : @wannabebawse
                </p>
                <a href="http://instagram.com/wannabebawse" target="_blank" rel="noopener noreferrer">
          <Instagram />
        </a>
            </div>

            <div className="contact_type">
                <p className="buttonMedium">
                   Facebook : @wannabebawse
                </p>
                <a href="https://www.facebook.com/wannabebawse" target="_blank" rel="noopener noreferrer">
          <Facebook />
        </a>
            </div>
           
        
            <Footer/>
        </div>
    )
}

export default ContactUs
