import React, { useState, useEffect } from "react";
import "./Home.css";
import "./index.css";
import ImageSlider from "./ImageSlider";
import Header from "./Header";
import Button from "./Button";
import DetailsSlider from "./DetailsSlider";
import Footer from "./Footer";
import ExtrasCard from "./ExtrasCard";
import { ReactComponent as CRight } from "./svg/caret_closed_right.svg";
import { ReactComponent as PaymentMethods } from "./svg/payment_methods.svg";
import { Link } from "react-router-dom";
import { ImageContext } from "./context/ImageContext";
import { ReactComponent as Map } from "./svg/map.svg";
import GoToTop from "GoToTop";

import feature1 from "./images/ringBinder.webp";
import feature2 from "./images/clouds/meal_planner.webp";
import feature3 from "./images/waves/3B1.webp";
import feature4 from "./images/dunes/6C.webp";
import { AddOns } from "StepFour";

import cloudsDated from "./images/clouds/3A1.webp";
import wavesDated from "./images/waves/3B1.webp";
import dunesDated from "./images/dunes/3C1.webp";

import cloudsUndated from "./images/clouds/3A2.webp";
import wavesUndated from "./images/waves/3B2.webp";
import dunesUndated from "./images/dunes/3C2.webp";

import { Themes } from "StepOne";

import Lottie from "react-lottie";
import SlideLottie from "./lottie/swipe.json";

import hero1 from "./images/1.webp";

const Planner1 = [
  {
    image: cloudsDated,
  },
  {
    image: wavesDated,
  },
  {
    image: dunesDated,
  },
];

const Planner2 = [
  {
    image: cloudsUndated,
  },
  {
    image: wavesUndated,
  },
  {
    image: dunesUndated,
  },
];

const Planners = [
  {
    name: "Dated Pages",
    writeup:
      "This planner takes you through a 12-month journey capturing moments of your life outright, from planning to execution.",
    serial: Planner1,
  },

  {
    name: "Undated Pages",
    writeup:
      "With 182 single day dedicated pages ,this is curated for someone who is obessed with planning the day throughout. ",
    serial: Planner2,
  },
];

const PlannerTypes = [];

function Home() {
  const [slides, setSlides] = useState(Planner1);

  const popupBgOptions = {
    loop: true,
    autoplay: true,
    animationData: SlideLottie,
    rendererSettings: {
      // preserveAspectRatio: "xMidYMid slice"
    },
  };

  return (
    <div className="Home">
      <Header type="LCH" />
      <div className="marquee">
        <p className="small" id="marquee">
          Buy Now And Get Ikigai & Goal Setting Worksheet Add On FREE! | FREE
          SHIP on all orders with minimum amount ₹1000/- | Free Ship for
          Delhi-NCR Orders, Use code DELHINCR
        </p>
      </div>

      <main>
        {/*------------------------------HERO---------------------------- */}
        <section className="hero__section">
          <p className="hl hero_headline">
            You get to make your personalised theme planner for 2022 !
          </p>
          <div className="image_details_slider_container">
            <div className="Image__slider__box">
              <div className="image_popup_container">
                <Lottie options={popupBgOptions} height={100} width={100} />
                <p className="ll">Swipe to change</p>
              </div>

              <ImageSlider />
            </div>
          </div>

          <ul>
            <li>Quote of your choice on the cover </li>
            <li>Your name and pic on the first page</li>
            <li>An interior to match your theme</li>
            <li>Variety of addons curated for your needs</li>
          </ul>

          <div className="hero_banner">
            <p className="tl">
              <del>₹ 1600</del>
            </p>
            <p className="hm">Starting at ₹950</p>
            <ul>
              <li>Base planner + exclusive addons</li>
              <li>Variety of addons to choose from</li>
              <li>Free shipping above ₹1000</li>
            </ul>
          </div>
        </section>

        {/**--------------------------------Themes----------------------------------- */}
        <section className="themes__section">
          <p className="ds">Themes</p>
          <div className="image_details_slider_container">
            <div className="Image__slider__box">
              <ImageSlider Images={slides} />
            </div>

            <ImageContext.Provider value={{ slides, setSlides }}>
              <DetailsSlider Details={Themes} />
            </ImageContext.Provider>
          </div>
        </section>

        {/*------------------------------Features 1, 2, 3, 4, 5 ---------------------------- */}

        <section className="features__section">
          <p className="ds">Features</p>

          <div className="featuresSection">
            <img src={feature1} alt="Ring Binder" className="featureImage" />
            <div className="featureDetails">
              <p className="hs">Ring Binders</p>
              <p className="bl">
                Planner that allows you to remove & swap the pages inside
              </p>
            </div>
          </div>

          <div className="featuresSection">
            <div className="featureDetails">
              <p className="hs">100 gsm sheets</p>
              <p className="bl">
                Knowing standard GSM for what you need can be incredibly useful
              </p>
            </div>
            <img src={feature2} alt="" className="featureImage" />
          </div>

          <div className="featuresSection">
            <img src={feature3} alt="" className="featureImage" />
            <div className="featureDetails">
              <p className="hs">Tinted Themes</p>
              <p className="bl">
                All three themes have easy-to-eyes muted pastel aesthetics
              </p>
            </div>
          </div>

          

          <div className="featuresSection">
           
            <div className="featureDetails">
              <p className="hs">A5 size Hardcover</p>
              <p className="bl">
                Perfect Planner size for easy usage and storage
              </p>
            </div>
            <img src={hero1} alt="" className="featureImage" />
          </div>
        </section>

        {/*------------------------------Planner Types---------------------------- */}

        <section className="types__section">
          <p className="ds">Planner Types</p>
          <div className="image_details_slider_container">
            <div className="Image__slider__box">
              <ImageSlider Images={Planner1} />
            </div>

            <ImageContext.Provider value={{ Planner2, setSlides }}>
              <DetailsSlider Details={Planners} />
            </ImageContext.Provider>
          </div>
        </section>

        {/** ---------------- Types Description---------------------------------- */}

        <section className="types__description__section">
          <div className="featuresSection">
            <img
              src={cloudsDated}
              alt="Dated Pages Description"
              className="featureImage"
            />
            <div className="featureDetails">
              <p className="hs">Dated pages</p>
              <ul>
                <li>Dates numbered</li>
                <li>3 days on every page</li>
                <li>Pages for 12 months </li>
              </ul>
            </div>
          </div>

          <div className="featuresSection">
            <div className="featureDetails">
              <p className="hs">Undated Pages</p>
              <ul>
                <li>You write the dates</li>
                <li>Single day pages</li>
                <li>Pages for 6 months</li>
              </ul>
            </div>
            <img src={dunesUndated} alt="" className="featureImage" />
          </div>

          <div className="types_banner">
            <p className="bl"> <b>Undated page bundle</b>  is now available in the addons to complete a full year</p>
          </div>
        </section>

        {/*------------------------------Add ons---------------------------- */}
        <section className="addons__section">
          <p className="ds">Planner Add Ons</p>

          <div className="image_details_slider_container">
            <div className="Image__slider__box">
              <ImageSlider Images={slides}  />
            </div>
            <ImageContext.Provider value={{ slides, setSlides }}>
              <DetailsSlider Details={AddOns} />
            </ImageContext.Provider>
          </div>
        </section>

        {/*------------------------------Add ons Description---------------------------- */}
        <section className="addons__description__section">
          <p className="ds align_left">Addons for you</p>
          <p className="bl">We have special sets of addons to  choose from, colored in every theme</p>
          <div className="addons__info">
            <div className="addons__info__col">
              <ul>
                <li>Ikigai worksheet</li>
                <li>Productivity bundle</li>
                <li>Periods Tracker</li>
                <li>Fitness bundle</li>
                <li>Meal planning </li>
                <li>Finance bundle</li>
                <li>Extra blank bundle</li>
                <li>Undated pages</li>
              </ul>
            </div>
          </div>
        </section>
        {/*------------------------------Notebooks Ads---------------------------- */}

        {/**   <div className="highlightedSection ad">
          <img
            src="https://images.unsplash.com/photo-1601001435957-74f0958a93fb?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8bm90ZWJvb2t8ZW58MHwyfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
            alt=""
            className="adImage"
          />
          <div className="adDetails">
            <p className="hs">Themed Notebooks:</p>
           <p className="bl">
              These notebooks are perfectly simple and easy to distinguish.
              available in popular covers.
            </p>
            <Button title="Shop Now" icon="next" />
          </div>
        </div>
         */}

        {/*------------------------------ Rewind 2021 (Reviews) ---------------------------- */}

         <section className="reviews__section">
           <p className="hl align_left">More than 200 sold</p>
         
            <Map />
            
            <p className="bl">We outsold our products in 2021 while delivering pan India. Planners 2022 have been redesigned for better experience & are also available your touch of personalization</p>
            <div className="hero_banner">
            <p className="tl">
              <del>₹ 1600</del>
            </p>
            <p className="hm">Starting at ₹950</p>
            <ul>
              <li>Base planner + exclusive addons</li>
              <li>Variety of addons to choose from</li>
              <li>Free shipping above ₹1000</li>
            </ul>
          </div>
       </section>
      

        {/*------------------------------You may like this---------------------------- */}
        {/**  
        * <div className="you_may_like_this_container">
          <div className="you_may_like_this_heading">
            <p className="hs">You may like this</p>
            <CRight />
          </div>
          <div className="you_may_like_this">
            <ExtrasCard />
            <ExtrasCard />
            <ExtrasCard />
            <ExtrasCard />
          </div>
        </div>
      */}
      </main>
      <div className="payment_method_container">
        <p className="tm">Accepted payment methods</p>
        <PaymentMethods />
      </div>

      <Footer />

      <Link to="step0">
        <div className="home__floatingButton">
          <Button title="Make my planner" icon="next" />
        </div>
      </Link>

      <GoToTop />
    </div>
  );
}

export default Home;
