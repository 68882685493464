import React from "react";
import './Loading.css';
import { useState } from "react";
//let path=document.getElementById('loader').querySelector('path');

//path.setAttribute("stroke-dasharray", pathLength);
//path.setAttribute("stroke-dashoffset", pathLength);


//path.innerHTML="";



function Loading({step}) {
  let pathLength=1080

  const [divisor, setDivisor] = useState( (step==="1") ? 1.2 : (step==="2") ? 1.6 :(step==="3") ? 2.2:(step==="4") ? 3.5:(step==="5") ? 7.5:15);
  const [divisorPre, setDivisorP] = useState( (divisor===1.2) ? 1 : (divisor===1.6) ? 1.2 :(divisor===2.2) ? 1.6:(divisor===3.5) ?2.2:(divisor===7.5) ? 3.5:7.5);
  console.log(divisor);
  console.log(divisorPre)
  
  return (
    <div className="Loader">
      <svg
        id="loader"
        width="336"
        height="336"
        viewBox="0 0 354 354"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M349 177C349 271.993 271.993 349 177 349C82.007 349 5 271.993 5 177C5 82.007 82.007 5 177 5C271.993 5 349 82.007 349 177Z"
          stroke="#56313A" strokeWidth="4"
          strokeLinecap="round"
          
          strokeDashoffset={pathLength}          
          strokeDasharray={pathLength}
        >
          <animate attributeName='stroke-dashoffset' begin='-1s' from={pathLength/divisorPre} dur='3s' to={pathLength/divisor} fill='freeze'/>
        </path>
      </svg>
    </div>
  );
}

export default Loading;
