import React from 'react'
import './Stepper.css'
import {ReactComponent as Check} from "./svg/checked.svg"
import {ReactComponent as Edit} from "./svg/edit.svg"


function Stepper(props) {
    return (
        <div className="stepper">

            { 
            
            props.stepNumber !=="" && 
            <div className="step__number">
                <h5>{props.stepNumber} of {props.totalSteps}</h5>
                <p className="small"> </p>
            </div>
            
            }



            { 
            
            props.stepIcon !== "" && 
            <div className="step__number">

            { 
                props.stepIcon === "check" ? <Check/> : props.stepIcon === "edit" ? <Edit/> : <Edit/>
            }

            </div>
            }


            <div className="step__name">
                <h5>{props.stepName}</h5>
                <p className="small">Next : {props.nextStep}</p>
            </div>

            
        </div>
    )
}

Stepper.defaultProps={
    stepNumber:"",
    stepIcon:""
}

export default Stepper
