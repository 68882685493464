import React from "react";
import "./index.css";
import "./Button.css";
import { ReactComponent as ArrowRight } from "./svg/caret_closed_right.svg";
import { ReactComponent as ArrowLeft } from "./svg/caret_closed_left.svg";
import { ReactComponent as SignIn } from "./svg/sign_in.svg";
import { ReactComponent as SignInWhite } from "./svg/sign_in_white.svg";
import { ReactComponent as Library } from "./svg/library.svg";
import Cart from "./Cart";
import { ReactComponent as Camera } from "./svg/camera.svg";
function Button(props) {


   

  return (
    <div className={props.type} >

      {props.title !== "" && <p className="ll">{props.title}</p>}

      {props.icon === "login" && <SignIn />}

      {props.icon === "login white" && <SignInWhite />}

      {props.icon === "next" && <ArrowRight />}

      {props.icon === "back" && <ArrowLeft />}

      {props.icon === "library" && <Library />}

      {props.icon === "camera" && <Camera/>}

    </div>
  );
}

Button.defaultProps = {
  title: "",
  type:"Button",
};

export default Button;
