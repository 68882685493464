import React, { createContext, useContext, useState, useEffect } from 'react'
import { auth } from "../firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";


const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({children}) {

   //const navigate = useNavigate();
    
    const [currentUser, setCurrentUser] = useState(null);
    const [userName, setUserName] = useState("");
    
    const logout =() =>{
        signOut(auth).then(() => {
            //navigate("/");
            console.log("Log out", userName)
          }).catch((error) => {
            // An error happened.
          });
    }
    const value = [currentUser, {userName : [userName, setUserName]}, logout];

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user);
            
        })
        return unsubscribe;
    }, [])

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}


