import React, { useState } from "react";
import "./Menu.css";
import { ReactComponent as Logo } from "./svg/WB_logo_shaded.svg";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as X } from "./svg/x_black.svg";
import { ReactComponent as Right } from "./svg/caret_closed_right.svg";


import Button from "./Button";
import { useAuth } from "./context/AuthContext";
import GoToTop from "GoToTop";

const closeMenu = () => {};

function Menu() {

  const [userName, setUserName] = useAuth()[1].userName;
  const currentUser = useAuth()[0];

  const logout = useAuth()[2];
  console.log(useAuth()[0]);

  
  const navigate = useNavigate();

  async function handleLogout() {
   

    try {
      await logout()
      navigate("/");
    } catch {
      console.log("Failed to log out")
    }
  }

  //console.log(useAuth()[1].userName);

  //const [userPlannerType, setUserPlannerType] = user_context.userPlannerType;

  //const [submenuActive, sehsubmenuActive] = useState(false);
  return (
    <div className="menu">
      <div className="menu_header">
        <Logo />
        <div onClick={() => navigate(-1)}>
          <X />
        </div>
      </div>
      <p className="hs" id="username">Hi {userName},</p>
      <div className="divider"></div>
      <div className="menu_content">
      { /* <div
          className="menu_option"
          onClick={(e) => {
            console.log("click deteacked");
            sehsubmenuActive((value) => !value);
          }}
        >
          <div className="menu_lable">
            <p className="hs">WB Planners</p>
            {submenuActive ? <Up /> : <Right />}
          </div>
          {submenuActive && (
            <div className="submenu">
              <div className="menu_lable">
                <p className="hs">Yearly Dated Planner</p>
                <Right />
              </div>
              <div className="divider"></div>
              <div className="menu_lable">
                <p className="hs">Undated Daily Planner</p>
                <Right />
              </div>
            </div>
          )}
        </div> */}
        <div className="menu_option">
          
          <div className="menu_lable">
            <p className="hs">Yearly Dated Planners</p>
            <Right />
          </div>          
        </div>

        <div className="divider"></div>

        {/*<div
          className="menu_option"
          onClick={(e) => {
            sehsubmenuActive((value) => !value);
          }}
        >*/}
        {/*}
          <div className="menu_option">
          <div className="menu_lable">
            <p className="hs">WB Notebooks</p>
            <Right />
          </div>
          
        </div>

        <div className="divider"></div>

        <div className="menu_option">
          <div className="menu_lable">
            <p className="hs">Undated Daily Planner</p>
            <Right />
          </div>          
        </div>

        <div className="divider"></div>

        <div className="menu_option">
          <div className="menu_lable">
            <p className="hs">WB Notebooks</p>
            <Right />
          </div>          
        </div>

        <div className="divider"></div>

        <div className="menu_option">
          <div className="menu_lable">
            <p className="hs">WB Stickers</p>
            <Right />
          </div>          
      </div>*/}

        <div className="divider"></div>
        { currentUser==null ? <Link to="/login">
        <Button  icon="login white" title="Log In"/>
        </Link> : <div className="logout__button" onClick={(e)=> handleLogout(e)} ><Button  icon="login white" title="Log Out"/></div>
        
        }
      </div>
      <GoToTop/>
    </div>
  );
}

export default Menu;
