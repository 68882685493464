
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Home from './Home';
import Step1 from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import StepFive from './StepFive';
import {useState} from 'react';
import Review from './Review';
import { UserContext } from './context/UserContext';
import Cart from './Cart';
import Checkout from './Checkout';
import Menu from './Menu';
import Login from './Login';
import RequireAuth from './RequireAuth';
import PrivacyPolicy from './PrivacyPolicy';
import CancellationPolicy from './CancellationPolicy';
import TermsAndConditions from './TermsAndConditions';
import About from 'About';
import StepZero from 'StepZero';
import ContactUs from 'ContactUs';
import PaymentSuccess from 'PaymentSuccess';
import { ReactComponent as WhatsAppLogo } from "./svg/float_whatsapp.svg";
function App() {

  

  const [userTheme, setUserTheme] = useState("Evening Clouds");
  const [userQuote, setUserQuote] = useState("");
 
  const [userPlannerType, setUserPlannerType] = useState("");  
  const [userPlannerPages, setUserPlannerPages] = useState("");
  const [userAddons, setUserAddons] = useState(null);
  const [userAddonsPrice, setUserAddonsPrice] = useState(0);
  const [userPlannerPrice, setUserPlannerPrice] = useState(0);
  const [userNameonPlanner, setUserNameonPlanner] = useState("");
  return (
    
    <div className="App">
      
      <Router>
      <Routes>
        <Route path="/" element={ <Home/>  } />

        
        <Route path="/step0" element={ <StepZero/>  } />
        <Route path="step1" element={ <RequireAuth> <UserContext.Provider value={{userTheme:[userTheme, setUserTheme]}}> <Step1/> </UserContext.Provider>  </RequireAuth> } />        
        <Route path="step2" element={ <RequireAuth> <UserContext.Provider value={{ userQuote:[userQuote, setUserQuote]}}>  <StepTwo/> </UserContext.Provider> </RequireAuth>  } />
        <Route path="step3" element={ <RequireAuth> <UserContext.Provider value={{ userPlannerType:[userPlannerType, setUserPlannerType], 
        userPlannerPages: [userPlannerPages, setUserPlannerPages],userPlannerPrice: [userPlannerPrice, setUserPlannerPrice]}}> <StepThree/> </UserContext.Provider> </RequireAuth> } />
        <Route path="step4" element={ <RequireAuth>  <UserContext.Provider value={{userAddons: [userAddons, setUserAddons], userAddonsPrice: [userAddonsPrice, setUserAddonsPrice]}}><StepFour/> </UserContext.Provider> </RequireAuth> } />
        <Route path="step5" element={ <RequireAuth> <UserContext.Provider value={{userAddons: [userAddons, setUserAddons],userNameonPlanner:[userNameonPlanner, setUserNameonPlanner]}}><StepFive/> </UserContext.Provider> </RequireAuth> } />
        <Route path="review" element={ <RequireAuth> <UserContext.Provider value={{userTheme:[userTheme, setUserTheme],  userQuote:[userQuote, setUserQuote], userPlannerType:[userPlannerType, setUserPlannerType], 
        userPlannerPages: [userPlannerPages, setUserPlannerPages],userPlannerPrice: [userPlannerPrice, setUserPlannerPrice], userAddonsPrice: [userAddonsPrice, setUserAddonsPrice], userAddons: [userAddons, setUserAddons]}}> <Review/> </UserContext.Provider>  </RequireAuth> } />
        <Route path="cart" element={ <RequireAuth> <Cart/> </RequireAuth> } />
        <Route path="checkout" element={ <RequireAuth>  <Checkout/> </RequireAuth> } />
        <Route path="menu" element={ <Menu/> } />
        <Route path="login" element={ <Login/> } />
        <Route path="privacy" element={ <PrivacyPolicy/>} />
        <Route path="cancellation" element={ <CancellationPolicy/> } />
        <Route path="terms" element={ <TermsAndConditions/> } />
        <Route path="aboutus" element={ <About/> } />
        <Route path="contactus" element={ <ContactUs/> } />
        <Route path="paymentdone" element={ <PaymentSuccess/> } />
      </Routes>
      </Router>
      
      <div className="whatsapp_float">
      <a href="http://wa.me/917042336219" target="_blank" rel="noopener noreferrer">
        <WhatsAppLogo />
        </a>
      </div>

    </div>
  );
}

export default App;
